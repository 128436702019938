import axios from 'axios';

let prefix = "/sneaker-inventory"

const UserSneakerInventoryApi = {
	getSneakerInventoryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	changeOrigin(payload){
		return axios.post( prefix + "/sneaker/change-origin", payload)
	},
}

export default UserSneakerInventoryApi;