import axios from 'axios';

let prefix = "/sneaker-level-history"

const UserSneakerLevelHistoryApi = {
	
	getUserSneakerLevelHistoryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default UserSneakerLevelHistoryApi;