import axios from 'axios';

let prefix = "/marketplace"

const MarketplaceApi = {
	
	getMarketplaceList(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},
	getMarketplaceInfo(payload){
		return axios.get( prefix + "/get-admin", {params: payload})
	},
	listMarketplaceStatus(payload){
		return axios.get( prefix + "/list-status", {params: payload})
	},
	listMarketplaceable(payload){
		return axios.get( prefix + "/list-marketplaceable", {params: payload})
	},
}

export default MarketplaceApi;