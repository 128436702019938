import axios from 'axios';

let prefix = "/sneaker-trade-percentage"

const SneakerTradePercentageApi = {
	
	getSneakerTradePercentageList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateSneakerTradePercentage(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default SneakerTradePercentageApi;