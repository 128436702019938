import axios from 'axios';

let prefix = "/gem-trade-percentage"

const GemTradePercentageApi = {
	
	getGemTradePercentageList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateGemTradePercentage(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default GemTradePercentageApi;