import axios from 'axios';

let prefix = "/user-level"

const UserLevelApi = {
	listUserLevel(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createUserLevel(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateUserLevel(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveUserLevel(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
}

export default UserLevelApi;