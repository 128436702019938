import axios from 'axios';

let prefix = "/sneaker-type-mint"

const SneakerTypeMintApi = {
	listSneakerTypeMint(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateSneakerTypeMint(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default SneakerTypeMintApi;