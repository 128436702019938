import axios from 'axios';

let prefix = "/sneaker"

const SneakerApi = {
	listSneaker(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listSneakerParent(payload){
		return axios.get( prefix + "/list-parent", {params: payload})
	},
	getSneakerInfo(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	createSneaker(payload){
		return axios.post( prefix + "/create", payload)
	},

	
}

export default SneakerApi;