import axios from 'axios';

let prefix = "/user"
let prefix_auth = "/auth"

const UserApi = {
	
	getUserList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getGenderTypes(payload){
		return axios.get( prefix + "/gender/list", {params: payload})
	},
	changePassword(payload){
		return axios.post( prefix + "/profile-password/update", payload)
	},
	updateUserProfile(payload){
		return axios.post( prefix + "/update", payload)
	},
	forgotPassword(payload){
		return axios.post( prefix_auth + "/reset-password", payload)
	},
	verifyEmail(payload){
		return axios.post( prefix + "/verifyEmailToken", payload)
	},	
	getUserInfo(id, payload){
		return axios.get( prefix + "/get/"+id, {params: payload})
	},
	createUser(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateUser(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveUser(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	updateUserPassword(payload){
		return axios.post( prefix + "/password/update", payload)
	},
	sendAccountActivation(payload){
		return axios.post( prefix + "/activation-email/send", payload)
	},
	toggleUserExclusive(payload){
		return axios.post( prefix + "/toggle-exclusive", payload)
	},

}

export default UserApi;