import axios from 'axios';

let prefix = "/user-token-stake"

const UserTokenStakeApi = {
	
	listUserTokenStake(payload){
		return axios.get( prefix + "/list-all", {params: payload})
	},
}

export default UserTokenStakeApi;