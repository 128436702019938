import axios from 'axios';

let prefix = "/shoebox-inventory"

const UserShoeboxApi = {
	listUserShoebox(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default UserShoeboxApi;