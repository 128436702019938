import axios from 'axios';

let prefix = "/token-stake"

const TokenStakeApi = {
	
	listTokenStake(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listTokenStakeBnbPayout(payload){
		return axios.get( prefix + "/list-bnb-payout", {params: payload})
	},
	getTokenStake(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	createTokenStake(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateTokenStake(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default TokenStakeApi;