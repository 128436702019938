import axios from 'axios';

let prefix = "/event"

const EventDateApi = {
	getEventDateList(payload){
		return axios.get( prefix + "/list-date", {params: payload})
	},
	deleteEventDate(payload){
		return axios.delete( prefix + "/delete-date", {params: payload})
	},
	createEventDate(payload){
		return axios.post( prefix + "/create-date", payload)
	},
	updateEventDate(payload){
		return axios.post( prefix + "/update-date", payload)
	},
}

export default EventDateApi;