import axios from 'axios';

let prefix = "/shoebox"

const ShoeboxApi = {
	listShoebox(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createShoebox(payload){
		return axios.post( prefix + "/create", payload)
	},
}

export default ShoeboxApi;