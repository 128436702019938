import axios from 'axios';

let prefix = "/user-treasure-box"

const UserTreasureBoxApi = {
	exportUserTreasureBoxList(payload){
		return axios.get( prefix + "/export-list", {params: payload})
	},
}

export default UserTreasureBoxApi;