import axios from 'axios';

let prefix = "/shoebox-rarity"

const ShoeboxRarityApi = {
	listShoeboxRarity(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default ShoeboxRarityApi;