import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import( '@/views/_general/Login')
const ResetPassword = () => import('@/views/_general/ResetPassword')
const UpdateBnbConvertionRate = () => import('@/views/_general/UpdateBnbConvertionRate')

// Pages
const AuthProfile = () => import( '@/views/_auth/AuthProfile')

const Home = () => import( '@/views/_general/Home')

const UserList = () => import( '@/views/Users/UserList')
const UserProfile = () => import( '@/views/Users/UserProfile')

// User level
const UserLevelList = () => import( '@/views/UserLevels/UserLevelList')

// User level
const UserTokenList = () => import( '@/views/UserTokens/UserTokenList')

// Sneaker type
const SneakerTypeList = () => import( '@/views/SneakerTypes/SneakerTypeList')

// token type
const TokenTypeList = () => import( '@/views/TokenTypes/TokenTypeList')

// starter packs
const StarterPackList = () => import( '@/views/StarterPacks/StarterPackList')
const StarterPackTransactionList = () => import( '@/views/StarterPacks/StarterPackTransactionList')

// Sneaker type
const SneakerProfile = () => import( '@/views/Sneakers/SneakerProfile')
const SneakerList = () => import( '@/views/Sneakers/SneakerList')

// Sneaker rarity
const SneakerRarityList = () => import( '@/views/SneakerRaritys/SneakerRarityList')

const MintBurnToken = () => import( '@/views/MintBurns/MintBurnToken')

const SupportiveSingle = () => import( '@/views/Supports/SupportiveSingle')

const AuditTrailList = () => import( '@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import( '@/views/AuditTrails/AuditTrailProfile')

const ReportList = () => import( '@/views/Reports/ReportList')

const WalletTransactionList = () => import( '@/views/WalletTransactions/WalletTransactionList')

const WalletOtpHistoryList = () => import( '@/views/WalletOtpHistories/WalletOtpHistoryList')

const UserSneakerLevelHistoryList = () => import( '@/views/UserSneakerLevelHistories/UserSneakerLevelHistoryList')

const SneakerLevelList = () => import( '@/views/SneakerLevels/SneakerLevelList')
const SneakerLevelProfile = () => import( '@/views/SneakerLevels/SneakerLevelProfile')

const ShoeboxList = () => import( '@/views/Shoeboxes/ShoeboxList')

const UserGemList = () => import( '@/views/UserGems/UserGemList')
const GemTypeList = () => import( '@/views/GemTypes/GemTypeList')
const GemStarList = () => import( '@/views/GemStars/GemStarList')

const MarketplaceList = () => import( '@/views/Marketplaces/MarketplaceList')
const MarketplaceProfile = () => import( '@/views/Marketplaces/MarketplaceProfile')

const StakeHowToPlay = () => import( '@/views/Stakes/HowToPlay')
const StakeList = () => import( '@/views/Stakes/StakeList')
const StakeProfile = () => import( '@/views/Stakes/StakeProfile')

const AnnouncementList = () => import( '@/views/Announcements/AnnouncementList')

const SystemVariableList = () => import( '@/views/SystemVariables/SystemVariableList')

const GemTradePercentageList = () => import( '@/views/GemTradePercentages/GemTradePercentageList')
const SneakerTradePercentageList = () => import( '@/views/SneakerTradePercentages/SneakerTradePercentageList')

const SneakerTypeMintList = () => import( '@/views/SneakerTypeMints/SneakerTypeMintList')

const TokenStakeList = () => import( '@/views/TokenStakes/TokenStakeList')
const TokenStakeBnbPayoutList = () => import( '@/views/TokenStakes/TokenStakeBnbPayoutList')
const TokenStakeProfile = () => import( '@/views/TokenStakes/TokenStakeProfile')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [ 1,5 ], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },
	{ path: '/partner/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: true, title: 'User List' } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile' } },

	//user lvel
	{ path: '/user-level/list', name: 'user_level.list', component: UserLevelList, meta: { auth: true, title: 'User Level List' } },

	// sneaker type
	{ path: '/sneaker-type/list', name: 'sneaker_type.list', component: SneakerTypeList, meta: { auth: true, title: 'Sneaker Type List' } },

	// token type
	{ path: '/token-type/list', name: 'token_type.list', component: TokenTypeList, meta: { auth: true, title: 'Token Type List' } },

	// sneaker rarity
	{ path: '/sneaker-rarity/list', name: 'sneaker_rarity.list', component: SneakerRarityList, meta: { auth: true, title: 'Sneaker Rarity List' } },

	//sneaker
	{ path: '/sneaker/list', name: 'sneaker.list', component: SneakerList, meta: { auth: true, title: 'Sneaker List' } },
	{ path: '/sneaker/profile/:id', name: 'sneaker.profile', component: SneakerProfile, meta: { auth: true, title: 'Sneaker Profile' } },

	// starter pack
	{ path: '/starter-pack/list', name: 'starter-pack.list', component: StarterPackList, meta: { auth: true, title: 'Starter Pack List' } },
	{ path: '/starter-pack/transaction', name: 'starter-pack.transaction', component: StarterPackTransactionList, meta: { auth: true, title: 'Starter Pack Transaction List' } },
	
	// mint burn
	{ path: '/mint-burn/list', name: 'mint-burn.list', component: MintBurnToken, meta: { auth: true, title: 'NFT Report' } },

	// support
	{ path: '/support/terms-of-use', name: 'support.terms-of-use', component: SupportiveSingle, meta: { auth: true, title: 'T&C', params: { type: 'terms-of-use' } } },
	{ path: '/support/privacy-policy', name: 'support.privacy-policy', component: SupportiveSingle, meta: { auth: true, title: 'Privacy Policy', params: { type: 'privacy-policy' } } },
	{ path: '/support/token-stake-introduction', name: 'support.token-stake-introduction', component: SupportiveSingle, meta: { auth: true, title: 'Token Stake Introduction', params: { type: 'token-stake-introduction' } } },
	{ path: '/support/token-stake-rules', name: 'support.token-stake-rules', component: SupportiveSingle, meta: { auth: true, title: 'Token Stake Rules', params: { type: 'token-stake-rules' } } },
	{ path: '/support/token-stake-bnb-payout', name: 'support.token-stake-bnb-payout', component: SupportiveSingle, meta: { auth: true, title: 'Token Stake Bnb Payout Info', params: { type: 'token-stake-bnb-payout' } } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: true, title: 'Audit Trail List' } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile', component: AuditTrailProfile, meta: { auth: true, title: 'Audit Trail Profile' } },
	
	{ path: '/report', name: 'report', component: ReportList, meta: { auth: true, title: 'Report List' } },
	
	{ path: '/wallet-transaction/list', name: 'wallet-transaction.list', component: WalletTransactionList, meta: { auth: true, title: 'Wallet Transaction List' } },
	
	{ path: '/otp-history/list', name: 'otp-history.list', component: WalletOtpHistoryList, meta: { auth: true, title: 'OTP History List' } },
	
	{ path: '/sneaker-level-history/list', name: 'sneaker-level-history.list', component: UserSneakerLevelHistoryList, meta: { auth: true, title: 'Sneaker Level History List' } },
	
	{ path: '/sneaker-level/list', name: 'sneaker-level.list', component: SneakerLevelList, meta: { auth: true, title: 'Sneaker Level List' } },
	{ path: '/sneaker-level/profile/:id', name: 'sneaker-level.profile', component: SneakerLevelProfile, meta: { auth: true, title: 'Sneaker Level Profile' } },

	{ path: '/shoebox/list', name: 'shoebox.list', component: ShoeboxList, meta: { auth: true, title: 'Shoebox List' } },

	{ path: '/artifact/list', name: 'artifact.list', component: UserGemList, meta: { auth: true, title: 'Artifact List' } },
	{ path: '/artifact-type/list', name: 'gem-type.list', component: GemTypeList, meta: { auth: true, title: 'Artifact Type List' } },
	{ path: '/artifact-star/list', name: 'gem-star.list', component: GemStarList, meta: { auth: true, title: 'Artifact Star List' } },
	
	{ path: '/marketplace/list', name: 'marketplace.list', component: MarketplaceList, meta: { auth: true, title: 'Marketplace List' } },
	{ path: '/marketplace/profile/:id', name: 'marketplace.profile', component: MarketplaceProfile, meta: { auth: true, title: 'Marketplace Profile' } },
	
	{ path: '/stake/how-to-play', name: 'stake.how-to-play', component: StakeHowToPlay, meta: { auth: true, title: 'Stake How To Play' } },
	{ path: '/stake/list', name: 'stake.list', component: StakeList, meta: { auth: true, title: 'Stake List' } },
	{ path: '/stake/profile/:id', name: 'stake.profile', component: StakeProfile, meta: { auth: true, title: 'Stake Profile' } },

	{ path: '/user-token/list', name: 'user-token.list', component: UserTokenList, meta: { auth: true, title: 'User Token List' } },
	
	{ path: '/announcement/list', name: 'announcement.list', component: AnnouncementList, meta: { auth: true, title: 'Announcement List' } },
	
	{ path: '/settings/list', name: 'system-variable.list', component: SystemVariableList, meta: { auth: true, title: 'System Variable List' } },

	{ path: '/artifact-trade-percentage/list', name: 'artifact-trade-percentage.list', component: GemTradePercentageList, meta: { auth: true, title: 'Artifact Trade Percentage' } },
	{ path: '/sneaker-trade-percentage/list', name: 'sneaker-trade-percentage.list', component: SneakerTradePercentageList, meta: { auth: true, title: 'Sneaker Trade Percentage' } },

	{ path: '/sneaker-type-mint/list', name: 'sneaker-type-mint.list', component: SneakerTypeMintList, meta: { auth: true, title: 'Sneaker Type Mint Percentage List' } },
	
	{ path: '/token-stake/list', name: 'token-stake.list', component: TokenStakeList, meta: { auth: true, title: 'Token Stake List' } },
	{ path: '/token-stake/list-bnb-payout', name: 'token-stake.list-bnb-payout', component: TokenStakeBnbPayoutList, meta: { auth: true, title: 'BNB Payout List' } },
	{ path: '/token-stake/profile/:id', name: 'token-stake.profile', component: TokenStakeProfile, meta: { auth: true, title: 'Token Stake Profile' } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },
	{ path: '/update/bnb-convertion-rate/:token', name: 'update.bnb-convertion-rate', component: UpdateBnbConvertionRate, meta: { auth: null, title: 'Update Bnb Conversion Rate' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

export default router