import axios from 'axios';

let prefix = "/mint-burn"

const TokenTypeApi = {
	
	getMintBurnInfo(payload){
		return axios.get( prefix + "/info", {params: payload})
	},

	mintToken(payload){
		return axios.post( prefix + "/mint/token", payload)
	},

	burnToken(payload){
		return axios.post( prefix + "/burn/token", payload)
	},

	burnShoebox(payload){
		return axios.post( prefix + "/burn/shoebox", payload)
	},
}

export default TokenTypeApi;