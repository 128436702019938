import axios from 'axios';

let prefix = "/token-type"

const TokenTypeApi = {
	
	getTokenTypeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateTokenType(payload){
		return axios.post( prefix + "/update", payload)
	},

	checkTokenAvailability(payload){
		return axios.get( prefix + "/check-token-availability", {params: payload})
	},

	updateBnbToUsdtConversionRate(payload){
		return axios.post( prefix + "/update-bnb-to-usdt-conversion-rate", payload)
	}
}

export default TokenTypeApi;