import axios from 'axios';

let prefix = "/wallet-otp"

const WalletOtpHistoryApi = {
	
	getWalletOtpHistoryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getOtpHistoryRefs(payload){
		return axios.get( prefix + "/refs", {params: payload})
	},
}

export default WalletOtpHistoryApi;