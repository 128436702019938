import axios from 'axios';

let prefix = "/starter-pack"

const StarterPackApi = {
	
	getStarterPackList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateStarterPack(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default StarterPackApi;