import axios from 'axios';

let prefix = "/stake"

const StakeApi = {
	
	getHowToPlay(payload){
		return axios.get( prefix + "/how-to-play", {params: payload})
	},

	updateHowToPlay(payload){
		return axios.post( prefix + "/update-how-to-play", payload)
	},

	listStake(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	getStake(payload){
		return axios.get( prefix + "/get", {params: payload})
	},

}

export default StakeApi;