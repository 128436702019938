import axios from 'axios';

let prefix = "/sneaker-level"

const SneakerLevelApi = {
	
	getSneakerLevelList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getSneakerLevelInfo(payload){
		return axios.get( prefix + "/info", {params: payload})
	},
	
}

export default SneakerLevelApi;