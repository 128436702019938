import axios from 'axios';

let prefix = "/gem-star"

const GemStarApi = {
	listGemStar(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default GemStarApi;