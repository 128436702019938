import axios from 'axios';

let prefix = "/sneaker-rarity"

const SneakerRarityApi = {
	listSneakerRarity(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},
	createSneakerRarity(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateSneakerRarity(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveSneakerRarity(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
}

export default SneakerRarityApi;