import axios from 'axios';

let prefix = "/user-gem"

const UserGemApi = {
	listUserGem(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	exportUserGemList(payload){
		return axios.get( prefix + "/export-list", {params: payload})
	},
	createUserGem(payload){
		return axios.post( prefix + "/create", payload)
	},
}

export default UserGemApi;