import axios from 'axios';

let prefix = "/sneaker-type"

const SneakerTypeApi = {
	listSneakerType(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},
	createSneakerType(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateSneakerType(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveSneakerType(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
}

export default SneakerTypeApi;