import axios from 'axios';

let prefix = "/system-variable"

const SystemVariableApi = {
	
	listSystemVariable(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	listRpcUrl(payload){
		return axios.get( prefix + "/rpc-url-list", {params: payload})
	},

	updateRpcUrl(payload){
		return axios.post( prefix + "/update-rpc-url", payload)
	},

}

export default SystemVariableApi;