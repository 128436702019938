import axios from 'axios';

let prefix = "/staker-session"

const StakeApi = {
	
	listStakerSession(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

}

export default StakeApi;